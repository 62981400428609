// directional-property mixins are shorthands
// for writing properties like the following
//
// @include margin(null 0 10px);
// ------
// margin-right: 0;
// margin-bottom: 10px;
// margin-left: 0;
//
// - or -
//
// @include border-style(dotted null);
// ------
// border-top-style: dotted;
// border-bottom-style: dotted;
//
// ------
//
// Note: You can also use false instead of null

@function collapse-directionals($vals) {
  $output: null;

  $A: nth( $vals, 1 );
  $B: if( length($vals) < 2, $A, nth($vals, 2));
  $C: if( length($vals) < 3, $A, nth($vals, 3));
  $D: if( length($vals) < 2, $A, nth($vals, if( length($vals) < 4, 2, 4) ));

  @if $A == 0 { $A: 0 }
  @if $B == 0 { $B: 0 }
  @if $C == 0 { $C: 0 }
  @if $D == 0 { $D: 0 }

  @if $A == $B and $A == $C and $A == $D { $output: $A          }
  @else if $A == $C and $B == $D         { $output: $A $B       }
  @else if $B == $D                      { $output: $A $B $C    }
  @else                                  { $output: $A $B $C $D }

  @return $output;
}

@function contains-falsy($list) {
  @each $item in $list {
    @if not $item {
      @return true;
    }
  }

  @return false;
}

@mixin directional-property($pre, $suf, $vals) {
  // Property Names
  $top:    $pre + "-top"    + if($suf, "-#{$suf}", "");
  $bottom: $pre + "-bottom" + if($suf, "-#{$suf}", "");
  $left:   $pre + "-left"   + if($suf, "-#{$suf}", "");
  $right:  $pre + "-right"  + if($suf, "-#{$suf}", "");
  $all:    $pre +             if($suf, "-#{$suf}", "");

  $vals: collapse-directionals($vals);

  @if contains-falsy($vals) {
    @if nth($vals, 1) { #{$top}: nth($vals, 1); }

    @if length($vals) == 1 {
      @if nth($vals, 1) { #{$right}: nth($vals, 1); }
    } @else {
      @if nth($vals, 2) { #{$right}: nth($vals, 2); }
    }

    // prop: top/bottom right/left
    @if length($vals) == 2 {
      @if nth($vals, 1) { #{$bottom}: nth($vals, 1); }
      @if nth($vals, 2) { #{$left}:   nth($vals, 2); }

    // prop: top right/left bottom
    } @else if length($vals) == 3 {
      @if nth($vals, 3) { #{$bottom}: nth($vals, 3); }
      @if nth($vals, 2) { #{$left}:   nth($vals, 2); }

    // prop: top right bottom left
    } @else if length($vals) == 4 {
      @if nth($vals, 3) { #{$bottom}: nth($vals, 3); }
      @if nth($vals, 4) { #{$left}:   nth($vals, 4); }
    }

  // prop: top/right/bottom/left
  } @else {
    #{$all}: $vals;
  }
}

@mixin margin($vals...) {
  @include directional-property(margin, false, $vals...);
}

@mixin padding($vals...) {
  @include directional-property(padding, false, $vals...);
}

@mixin border-style($vals...) {
  @include directional-property(border, style, $vals...);
}

@mixin border-color($vals...) {
  @include directional-property(border, color, $vals...);
}

@mixin border-width($vals...) {
  @include directional-property(border, width, $vals...);
}
