@mixin border-image($borders...) {
  $webkit-borders: ();
  $spec-borders: ();

  @each $border in $borders {
    $webkit-border: ();
    $spec-border: ();
    $border-type: type-of($border);

    @if $border-type == string or list {
      $border-str: if($border-type == list, nth($border, 1), $border);

      $url-str:       str-slice($border-str, 0, 3);
      $gradient-type: str-slice($border-str, 0, 6);

      @if $url-str == "url" {
        $webkit-border: $border;
          $spec-border: $border;
      }

      @else if $gradient-type == "linear" {
        $gradients: _linear-gradient-parser("#{$border}");
        $webkit-border: map-get($gradients, webkit-image);
        $spec-border:   map-get($gradients, spec-image);
      }

      @else if $gradient-type == "radial" {
        $gradients: _radial-gradient-parser("#{$border}");
        $webkit-border: map-get($gradients, webkit-image);
        $spec-border:   map-get($gradients, spec-image);
      }

      @else {
        $webkit-border: $border;
          $spec-border: $border;
      }
    }

    @else {
      $webkit-border: $border;
        $spec-border: $border;
    }

    $webkit-borders: append($webkit-borders, $webkit-border, comma);
    $spec-borders:   append($spec-borders,   $spec-border,   comma);
  }

  -webkit-border-image: $webkit-borders;
          border-image: $spec-borders;
          border-style: solid;
}

//Examples:
// @include border-image(url("image.png"));
// @include border-image(url("image.png") 20 stretch);
// @include border-image(linear-gradient(45deg, orange, yellow));
// @include border-image(linear-gradient(45deg, orange, yellow) stretch);
// @include border-image(linear-gradient(45deg, orange, yellow) 20 30 40 50 stretch round);
// @include border-image(radial-gradient(top, cover, orange, yellow, orange));
