//************************************************************************//
// Generate a variable ($all-text-inputs) with a list of all html5
// input types that have a text-based input, excluding textarea.
// http://diveintohtml5.org/forms.html
//************************************************************************//
$inputs-list: 'input[type="email"]',
              'input[type="number"]',
              'input[type="password"]',
              'input[type="search"]',
              'input[type="tel"]',
              'input[type="text"]',
              'input[type="url"]',

              // Webkit & Gecko may change the display of these in the future
              'input[type="color"]',
              'input[type="date"]',
              'input[type="datetime"]',
              'input[type="datetime-local"]',
              'input[type="month"]',
              'input[type="time"]',
              'input[type="week"]';

// Bare inputs
//************************************************************************//
$all-text-inputs: assign-inputs($inputs-list);

// Hover Pseudo-class
//************************************************************************//
$all-text-inputs-hover: assign-inputs($inputs-list, hover);

// Focus Pseudo-class
//************************************************************************//
$all-text-inputs-focus: assign-inputs($inputs-list, focus);



// You must use interpolation on the variable:
// #{$all-text-inputs}
// #{$all-text-inputs-hover}
// #{$all-text-inputs-focus}

// Example
//************************************************************************//
//   #{$all-text-inputs}, textarea {
//     border: 1px solid red;
//   }



//************************************************************************//
// Generate a variable ($all-button-inputs) with a list of all html5
// input types that have a button-based input, excluding button.
//************************************************************************//
$inputs-button-list: 'input[type="button"]',
                     'input[type="reset"]',
                     'input[type="submit"]';

// Bare inputs
//************************************************************************//
$all-button-inputs: assign-inputs($inputs-button-list);

// Hover Pseudo-class
//************************************************************************//
$all-button-inputs-hover: assign-inputs($inputs-button-list, hover);

// Focus Pseudo-class
//************************************************************************//
$all-button-inputs-focus: assign-inputs($inputs-button-list, focus);

// Active Pseudo-class
//************************************************************************//
$all-button-inputs-active: assign-inputs($inputs-button-list, active);



// You must use interpolation on the variable:
// #{$all-button-inputs}
// #{$all-button-inputs-hover}
// #{$all-button-inputs-focus}
// #{$all-button-inputs-active}

// Example
//************************************************************************//
//   #{$all-button-inputs}, button {
//     border: 1px solid red;
//   }
