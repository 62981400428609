@mixin size($size) {
  $height: nth($size, 1);
  $width: $height;

  @if length($size) > 1 {
    $height: nth($size, 2);
  }

  @if $height == auto or (type-of($height) == number and not unitless($height)) {
    height: $height;
  }

  @if $width == auto or (type-of($height) == number and not unitless($width)) {
    width: $width;
  }
}
