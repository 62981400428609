@mixin position ($position: relative, $coordinates: null null null null) {

  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }

  $coordinates: unpack($coordinates);

  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);

  position: $position;

  @if ($top and $top == auto) or (type-of($top) == number) {
    top: $top;
  }

  @if ($right and $right == auto) or (type-of($right) == number) {
    right: $right;
  }

  @if ($bottom and $bottom == auto) or (type-of($bottom) == number) {
    bottom: $bottom;
  }

  @if ($left and $left == auto) or (type-of($left) == number) {
    left: $left;
  }
}
