// Adds keyframes blocks for supported prefixes, removing redundant prefixes in the block's content
@mixin keyframes($name) {
  $original-prefix-for-webkit:    $prefix-for-webkit;
  $original-prefix-for-mozilla:   $prefix-for-mozilla;
  $original-prefix-for-microsoft: $prefix-for-microsoft;
  $original-prefix-for-opera:     $prefix-for-opera;
  $original-prefix-for-spec:      $prefix-for-spec;

  @if $original-prefix-for-webkit {
    @include disable-prefix-for-all();
    $prefix-for-webkit: true !global;
    @-webkit-keyframes #{$name} {
      @content;
    }
  }
  @if $original-prefix-for-mozilla {
    @include disable-prefix-for-all();
    $prefix-for-mozilla: true !global;
    @-moz-keyframes #{$name} {
      @content;
    }
  }

  $prefix-for-webkit:    $original-prefix-for-webkit    !global;
  $prefix-for-mozilla:   $original-prefix-for-mozilla   !global;
  $prefix-for-microsoft: $original-prefix-for-microsoft !global;
  $prefix-for-opera:     $original-prefix-for-opera     !global;
  $prefix-for-spec:      $original-prefix-for-spec      !global;

  @if $original-prefix-for-spec {
    @keyframes #{$name} {
      @content;
    }
  }
}
