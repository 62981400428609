@mixin hide-text {
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
  }
}
