// Convert shorthand to the 4-value syntax

@function unpack($shorthand) {
  @if length($shorthand) == 1 {
    @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
  }
  @else if length($shorthand) == 2 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
  }
  @else if length($shorthand) == 3 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
  }
  @else {
    @return $shorthand;
  }
}

