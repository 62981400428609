@function assign-inputs($inputs, $pseudo: null) {
  $list : ();

  @each $input in $inputs {
    $input: unquote($input);
    $input: if($pseudo, $input + ":" + $pseudo, $input);
    $list: append($list, $input, comma);
  }

  @return $list;
}